<template>
  <div class="wrapper  flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="offerForm"
               label-width="140px"
               :rules="rules" ref="offerForm" class="add-form">
        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="offerForm.xingming" placeholder="请填写姓名"></el-input>
        </el-form-item>

        <el-form-item label="所属部门" prop="lishubumen">
          <el-input v-model="offerForm.lishubumen" placeholder="请填写所属部门"></el-input>
        </el-form-item>
        <el-form-item label="岗位名称" prop="gangwei">
          <el-input v-model="offerForm.gangwei" placeholder="请填写岗位名称"></el-input>
        </el-form-item>
        <el-form-item label="直接上级" prop="zhijieshangji">
          <el-select v-model="offerForm.zhijieshangji" placeholder="请选择直接上级" class="w-all">
            <el-option
                v-for="item in superior"
                :key="item.id"
                :label="item.xingming"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实习方式" prop="gongzuofangshi">
          <el-select v-model="offerForm.gongzuofangshi" placeholder="请选择实习方式" class="w-all">
            <el-option
                v-for="item in  wayOfWorking"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入职日期" prop="ruzhiriqi">
          <el-date-picker align="center"
              class="w-all"
              v-model="offerForm.ruzhiriqi"
              type="date"
              placement="bottom-start"
              placeholder="请选择入职日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实习周期" prop="shixizhouqi">
          <el-input v-model="offerForm.shixizhouqi" placeholder="请填写实习周期" type="number">
            <template slot="append">个月</template>
          </el-input>
        </el-form-item>
        <el-form-item label="入职地点" prop="baodaodizhi">
          <el-input v-model="offerForm.baodaodizhi" placeholder="请填写入职地点"></el-input>
        </el-form-item>
        <!--                <el-form-item label="发送邮箱" prop="fasongyouxaing">-->
        <!--                    <el-input v-model="offerForm.fasongyouxaing" placeholder="请填写发送邮箱"></el-input>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item label="抄送人员" prop="chaosongrenyuan">-->
        <!--                    <el-select v-model="offerForm.chaosongrenyuan" placeholder="请选择抄送人员" class="w-all">-->
        <!--                        <el-option-->
        <!--                            v-for="item in superior"-->
        <!--                            :key="item.value"-->
        <!--                            :label="item.label"-->
        <!--                            :value="item.value">-->
        <!--                        </el-option>-->
        <!--                    </el-select>-->
        <!--                </el-form-item>-->

        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('offerForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-sub  preview padding-lr">
      <div class="preview-info">
        <div class="preview-tips" v-if="!sys_offer_file">保存后可预览</div>
        <iframe v-else ref="iframe" frameborder="0" style="width: 100%;height: calc(100vh - 335px);"></iframe>
      </div>

      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载Offer Letter
        </el-button>

        <el-upload
            ref="upBtn"
            class="upload-demo"
            :action="actions"
            :data="{token:token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传Offer Letter反馈</el-button>

        </el-upload>

      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_offer_file">
        <div>反馈附件：</div>
        <el-link :href="upload_offer_file" target="_blank" type="primary">查看Offer Letter反馈附件
        </el-link>
      </div>
    </div>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {wayOfWorking} from "@/utils/const";
import {QAHOST, upFile} from "@/config";
import {downLoadOfferWord, getOfferShow, replyEntryNoticeFile, shangJiList, staffPracticeOffer} from "@/api/entry";
import {getOfferHtml} from "@/api/common";
import {getPageName, zhDate} from "@/utils";
import {orgShow} from "../../../../api/org";

export default {
  name: "offerList",
  data() {
    return {
      id: 0,
      superior: [],
      isSubmit: true,
      wayOfWorking: wayOfWorking,
      offerForm: {
        xingming: '',
        gangwei: '',
        lishubumen: '',
        zhijieshangji: '',
        gongzuofangshi: '',
        ruzhiriqi: null,
        shixizhouqi: '',
        baodaodizhi: '',
        fasongyouxaing: '',
        chaosongrenyuan: '',
      },
      rules: {
        xingming: rules.mustInput,
        gangwei: rules.mustInput,
        lishubumen: rules.mustInput,
        zhijieshangji: rules.mustSelect,
        gongzuofangshi: rules.mustInput,
        ruzhiriqi: rules.mustDate,
        shixizhouqi: rules.mustInput,
        baodaodizhi: rules.mustInput,
        fasongyouxaing: rules.mustInput,
      },
      sys_offer_file: '',
      upload_offer_file: '',
      iframe: '',
    }
  },
  created() {
    this.$emit("setIndex", '/admin/expertList', "实习生Offer Letter", true);
    this.shangJiList()
    if (Number(this.$route.params.id)) {
      this.id = Number(this.$route.params.id)
      this.offerShow()
    }else{
      this.orgShow()
    }
  },
  components: {},
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  methods: {

    async orgShow() {
      const res = await orgShow(this.$store.state.orgId)
      this.offerForm.baodaodizhi =res.data.dizhi
    },

    //  获取上级
    async shangJiList() {
      const res = await shangJiList()
      this.superior = res.data
    },
    //  获取offer Info
    async offerShow() {
      const res = await getOfferShow(this.id)
      this.sys_offer_file = res.data.sys_offer_file
      this.upload_offer_file = res.data.upload_offer_file ? QAHOST + '/' + res.data.upload_offer_file : ''
      this.offerForm = {
        xingming: res.data.xingming,
        gangwei: res.data.gangwei,
        lishubumen: res.data.lishubumen,
        zhijieshangji: Number(res.data.zhijieshangji),
        gongzuofangshi: res.data.gongzuofangshi,
        ruzhiriqi: new Date(res.data.ruzhiriqi),
        shixizhouqi: res.data.shixizhouqi,
        baodaodizhi: res.data.baodaodizhi,
        fasongyouxaing: res.data.fasongyouxaing,
        chaosongrenyuan: res.data.chaosongrenyuan,
      }
      if (res.data.sys_offer_file) {
        const data = await getOfferHtml(getPageName(res.data.sys_offer_file))
        this.iframe = QAHOST + '/' + data.data + '?v=' + new Date().getTime()
        this.$refs.iframe.contentWindow.location.replace(this.iframe)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (!this.isSubmit) return
      let _state = await staffPracticeOffer(
        this.offerForm.xingming,
        this.offerForm.gangwei,
        this.offerForm.lishubumen,
        this.offerForm.zhijieshangji,
        this.offerForm.gongzuofangshi,
        zhDate(new Date(this.offerForm.ruzhiriqi)),
        this.offerForm.shixizhouqi,
        this.offerForm.baodaodizhi,
        this.offerForm.fasongyouxaing,
        this.offerForm.chaosongrenyuan,
        this.id
      )
      this.isSubmit = true
      if (_state.status === 200) {
         loading.close();
        this.$message.success(_state.info)
        if (!this.id) {
          this.id = Number(_state.data)
          await this.$router.replace(`/admin/practiceTime/offerInfo/${_state.data}`)
        }
        await this.offerShow()
      } else {
         loading.close();
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await downLoadOfferWord(this.id, this.sys_offer_file)
      loading.close();
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.offerForm.xingming + 'Offer Leter.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象

    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await replyEntryNoticeFile(this.id, 2, url)
      this.$message.success('上传成功')
      await this.offerShow()
    },
  },
  filters: {},
  watch: {},
}
</script>
<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 330px);
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }
}
</style>
